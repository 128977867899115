import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";

import SearchBlock from "components/search/SearchBlock";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { ApolloProvider, Query } from "react-apollo";
import { autobind } from "react-decoration";
import { page } from "components/page";
import navigate from "lib/navigate";
import Link from "lib/Link";

const getDownloadDetail = gql`
  query getDownloadDetail($form: DownloadForm) {
    result: getDownloadDetail(Input: $form) {
      sn
      name
      description
      attachs {
        sn
        downloadType
        showName
        attachName
        attachPath
        downloadUrl
      }
    }
  }
`;

@withI18next(["common"])
@page
class DownloadDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resourceType: "databases",
      pageContent: "",
      sn: parseInt(this.props.search.sn),
    };
  }

  componentDidMount() {
    //console.log(this.props);
  }

  render() {
    console.debug(this.props.search.sn);
    console.debug(this.props.search);

    return (
      <Layout {...this.props}>
        <div className="main sp" id="center">
          <SearchBlock />
          <section className="main_content">
            <div className="container">
              <a
                className="accesskey"
                href="javascript:;"
                id="aC"
                accesskey="C"
                title={this.props.t("jumperrwd.common.mainContenctArea")}>
                :::
              </a>
              <h2>{this.props.t("hyint.common.download")}</h2>
              <a href="javascript:history.back();" className="back">
                {this.props.t("hyint.common.table.back")}
              </a>

              <ApolloProvider client={client.jumperrwdClient}>
                <Query
                  displayName="graphql"
                  query={getDownloadDetail}
                  fetchPolicy={"no-cache"}
                  variables={{
                    form: {
                      //sn : this.props.search.id
                      sn: this.state.sn,
                    },
                  }}>
                  {({ loading, data, refetch, error }) => {
                    if (error) return "error";
                    if (loading) return "Loading...";

                    console.debug(data);
                    if (data.result !== null) {
                      return (
                        <div className="detail_block">
                          <h3>{data.result.name}</h3>
                          <div className="article_content">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data.result.description,
                              }}></p>

                            {data.result.attachs &&
                              data.result.attachs.length > 0 && (
                                <p>
                                  {this.props.t(
                                    "jumperrwd.download.relatedDownload"
                                  )}
                                  ：
                                  {data.result.attachs.map((item, i) => {
                                    return (
                                      <p>
                                        <Link
                                          target="_blank"
                                          href={
                                            "/api/jumperrwdWs/downloadFile?sn=" +
                                            item.sn
                                          }>
                                          {item.showName}
                                        </Link>
                                      </p>
                                    );
                                  })}
                                </p>
                              )}
                          </div>
                        </div>
                      );
                    } else return "Error...";
                  }}
                </Query>
              </ApolloProvider>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default DownloadDetail;
